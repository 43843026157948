import React from "react"
import { Row, Col } from "reactstrap"

import Layout from "../components/layout/layout2"
import SEO from "../components/seo"
import imgBootstrap from "../images/videos/serie_bootstrap.png"
import imgReactEssencial from "../images/videos/serie_react-essencial.png"
import imgJSTotal from "../images/videos/jsTotal.png"
import imgVagrant from "../images/videos/serie_vagrant.png"
import imgRelampago from "../images/videos/serie_relampago.png"
import CardVideoCursos from "../components/CardVideoCursos"

const CursosTutoriais = () => {
  return (
    <Layout title="Cursos & Tutoriais">
      <SEO title="Cursos & Tutoriais" />
      <Row>
        <Col md="6">
          <CardVideoCursos
            image={imgBootstrap}
            link="https://www.youtube.com/playlist?list=PLZ4xIRSKcCtEIpYjU77slhfXvFUwrx5T8"
            title="Bootstrap Total"
            subtitle="Série de 21 vídeos, totalizando 5h 37min 51seg"
            status="Encerrada"
          />
        </Col>
        <Col md="6">
          <CardVideoCursos
            image={imgReactEssencial}
            link="https://www.youtube.com/playlist?list=PLZ4xIRSKcCtFVLMH6SD-vitf501utTcvi"
            title="React Essencial"
            subtitle="Série de 21 vídeos, totalizando 5h 10min 5seg"
            status="Encerrada"
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <CardVideoCursos
            image={imgJSTotal}
            link="https://www.youtube.com/playlist?list=PLZ4xIRSKcCtEvcgAgyqNUaeoeB1GNWyot"
            title="Javascript Total"
            subtitle="Série de 22 vídeos, totalizando 6h 49min 6seg"
            status="Em andamento"
          />
        </Col>
        <Col md="6">
          <CardVideoCursos
            image={imgVagrant}
            link="https://www.youtube.com/playlist?list=PLZ4xIRSKcCtFLcGzKM7hIPmkX4DvLEuMS"
            title="Vagrant"
            subtitle="Série de 5 vídeos, totalizando 1h 20min 40seg"
            status="Encerrada"
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <CardVideoCursos
            image={imgRelampago}
            link="https://www.youtube.com/playlist?list=PLZ4xIRSKcCtHaEABaqIVvOuUOvmJR9C3X"
            title="Tutoriais Relâmpago"
            subtitle="Série de 8 vídeos, totalizando 2h 38min 19seg"
            status="Encerrada"
          />
        </Col>
        <Col md="6"></Col>
      </Row>
    </Layout>
  )
}

export default CursosTutoriais

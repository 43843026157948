import React from "react"
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  CardSubtitle,
} from "reactstrap"

const CardVideoCursos = ({ image, link, title, subtitle, status }) => {
  return (
    <Card>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <CardImg top width="100%" src={image} />
      </a>
      <CardBody>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <CardTitle>
            <h4>{title}</h4>
          </CardTitle>
        </a>
        <CardSubtitle className="subtitulo text-info">{subtitle}</CardSubtitle>
        <Badge color={status === "Encerrada" ? "success" : "danger"}>
          {status}
        </Badge>
      </CardBody>
    </Card>
  )
}

export default CardVideoCursos
